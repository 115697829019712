import React, { useEffect } from "react"
import { NavLink, Link, useLocation } from "react-router-dom"
import { Row, Col, Navbar, Nav, NavItem, Container, NavLink as RSNavLink } from "reactstrap"
import BillgistWhiteLogo from "../../assets/images/billgist-white-logo.png"
import FacebookIcon from "../../assets/images/facebook-icon.png"
import InstagramIcon from "../../assets/images/instagram-icon.png"
import TwitterIcon from "../../assets/images/twitter-icon.png"
import "./footer.scss"

const Footer = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return (
    <footer className="bg-secondary font-avenir mt-auto">
      <Container>
        <Row className="py-5">
          <Col sm="4" md="3" className="px-0">
            <Navbar>
              <Container className="d-flex justify-content-center justify-content-sm-start">
                <Nav className="text-center text-sm-start" navbar>
                  <NavItem>
                    <RSNavLink tag={NavLink} to="/dashboard" className="fw-light text-light">
                      Dashboard
                    </RSNavLink>
                  </NavItem>
                  <NavItem>
                    <RSNavLink tag={NavLink} to="/aws-integration" className="fw-light text-light">
                      AWS Integrations
                    </RSNavLink>
                  </NavItem>
                  <NavItem>
                    <RSNavLink tag={NavLink} to="/notification" className="text-light">
                      Notifications
                    </RSNavLink>
                  </NavItem>
                  <NavItem>
                    <RSNavLink tag={NavLink} to="/organization" className="text-light">
                      Organization
                    </RSNavLink>
                  </NavItem>
                </Nav>
              </Container>
            </Navbar>
          </Col>
          <Col sm="4" md="6" className="d-flex flex-column align-items-center justify-content-center px-0">
            <Link to="/">
              <img className="d-block" src={BillgistWhiteLogo} alt="Billgist logo" width="150px" />
            </Link>
            <div className="pt-2 text-light font-weight-lighter small">Do not get surprised by your AWS bill</div>
            <div className="pt-2">
              <a
                href="https://www.facebook.com/billgistdotcom"
                target="_blank"
                className="pe-2"
                rel="noopener noreferrer"
              >
                <img src={FacebookIcon} alt="Facebook" width="20px" />
              </a>
              <a
                href="https://www.instagram.com/billgist_com/"
                target="_blank"
                className="pe-2"
                rel="noopener noreferrer"
              >
                <img src={InstagramIcon} alt="Instagram" width="20px" />
              </a>
              <a href="https://twitter.com/billgistdotcom" target="_blank" className="pe-2" rel="noopener noreferrer">
                <img src={TwitterIcon} alt="Twitter" width="20px" />
              </a>
            </div>
          </Col>
          <Col sm="4" md="3" className="px-0">
            <Navbar className="justify-content-center justify-content-sm-end">
              <Container className="d-flex justify-content-center justify-content-sm-start">
                <Nav navbar className="align-items-center align-items-sm-start">
                  <NavItem>
                    <a href="/blog" className="fw-light text-light nav-link">
                      Blog
                    </a>
                  </NavItem>
                  <NavItem>
                    <RSNavLink
                      className="fw-light text-light text-decoration-none"
                      href="mailto:help@billgist.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Contact
                    </RSNavLink>
                  </NavItem>
                  <NavItem>
                    <RSNavLink tag={NavLink} to="/terms-and-conditions" className="fw-light text-light">
                      Terms &amp; Conditions
                    </RSNavLink>
                  </NavItem>
                  <NavItem>
                    <RSNavLink tag={NavLink} to="/privacy-policy" className="fw-light text-light">
                      Privacy
                    </RSNavLink>
                  </NavItem>
                </Nav>
              </Container>
            </Navbar>
          </Col>
        </Row>
      </Container>
      <div className="landing-page-copyright text-white-50 text-center small py-2">
        Bellevue, WA 98008 &#169; {new Date().getFullYear()} Billgist. All rights reserved!
      </div>
    </footer>
  )
}

export default Footer
